import FontAwesomeIcon from './Icon'
import { graphql, useStaticQuery, Link } from 'gatsby'
import React from 'react'
import {
  Box,
  Container,
  Heading,
  Img,
  Flex,
  Txt,
  Link as RLink,
  Button,
} from 'rendition'
import balenaCloudLogo from '../img/balena-cloud.svg'
import { Post } from './Post'

function LatestBalenaCloud() {
  const data = useStaticQuery(graphql`
    query LatestBalenaCloudListQuery {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: { eq: "blog-post" }
            feature_image: { ne: null }
            visibility: { eq: "public" }
            tags: { in: "balenacloud" }
          }
        }
        limit: 3
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            id
            timeToRead
            fields {
              slug
            }
            frontmatter {
              postType
              title
              date(formatString: "MMMM DD, YYYY")
              feature_image
              excerpt
            }
          }
        }
      }
    }
  `)
  const posts = data.allMarkdownRemark.edges.map(({ node: post }) => ({
    title: post.frontmatter.title,
    excerpt: post.frontmatter.excerpt,
    postType: post.frontmatter.postType,
    date: post.frontmatter.date,
    image: post.frontmatter.feature_image,
    timeToRead: post.timeToRead,
    link: post.fields.slug,
    id: post.id,
  }))

  return (
    <Box pt={6} pb={5}>
      <Container>
        <Flex flexDirection="column">
          <Flex flexDirection={['column', 'column', 'row', 'row']} pb={3}>
            <Heading.h4 fontSize="34px" style={{ fontWeight: 400 }} pr={3}>
              Latest on
            </Heading.h4>{' '}
            <Img src={balenaCloudLogo} />
          </Flex>
          <Box>
            <Flex
              style={{ flexFlow: 'row wrap' }}
              flexDirection={['column', 'column', 'row', 'row']}
              mx={-2}
            >
              {posts.map((post) => (
                <Box
                  px={1}
                  my={3}
                  key={post.id}
                  width={['100%', '100%', 1 / 3, 1 / 3]}
                >
                  <Post {...post} />
                </Box>
              ))}
            </Flex>
            <Flex justifyContent="flex-end">
              <RLink is={Link} to="/tags/balenacloud">
                <Button plain primary my={4}>
                  View more{' '}
                  <Txt.span pl={2} fontSize={4}>
                    {' '}
                    <FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} />
                  </Txt.span>
                </Button>
              </RLink>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default LatestBalenaCloud
