import { Link } from 'gatsby'
import React from 'react'
import { Box, Heading, Flex, Img, Txt, Link as RLink } from 'rendition'
import styled from 'styled-components'

const StyledH4 = styled(Heading.h4)`
  font-weight: normal;
  align-self: start;
  line-height: 1.3;
  text-align: left;
`

const StyledLink = styled(RLink)`
  &:hover ${StyledH4}, &:focus ${StyledH4} {
    text-decoration: underline;
    color: #2a506f;
  }
  text-decoration: none;
`

export function Post(props) {
  return (
    <StyledLink is={Link} to={props.link}>
      <Flex m={2} alignItems="center" flexDirection="column">
        <Img src={props.image} width="100%" style={{ borderRadius: 10 }} loading="lazy"></Img>
        <Flex alignItems="center" flexDirection="column">
          <Txt.span
            mt={3}
            mb={2}
            fontSize={1}
            style={{ alignSelf: 'start', textTransform: 'capitalize' }}
            color="primary.main"
          >
            {props.postType}
          </Txt.span>
          <StyledH4 fontSize={3} color="text.main" bold>
            {props.title}
          </StyledH4>
          <Txt
            fontSize={1}
            my={2}
            color="text.light"
            style={{ lineHeight: 1.42 }}
          >
            {props.excerpt}
          </Txt>
          <Box style={{ alignSelf: 'start' }}>
            <Txt.span color="text.light" fontSize="12px" bold>
              {props.date}
            </Txt.span>
            <Txt.span
              color="text.light"
              fontSize="12px"
            >{` / ${props.timeToRead} min`}</Txt.span>
          </Box>
        </Flex>
      </Flex>
    </StyledLink>
  )
}
