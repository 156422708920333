import FontAwesomeIcon from './Icon'
import React from 'react'
import { Box, Container, Heading, Flex, Txt, Button } from 'rendition'

function Card(props) {
  return (
    <Flex
      alignItems="center"
      backgroundColor="#fff"
      width={['100%', '100%', 1 / 4, 1 / 4]}
      p={4}
      m={2}
      style={{ borderRadius: 10 }}
      flexDirection="column"
    >
      <Txt.span fontSize={5} color="primary.main">
        <FontAwesomeIcon icon={props.icon} />
      </Txt.span>
      <Heading.h4
        my={3}
        align="center"
        style={{ fontWeight: 'normal', lineHeight: 0.96, color: '#002676' }}
      >
        {props.title}
      </Heading.h4>
      <Txt.p
        fontSize={1}
        style={{ lineHeight: 1.71, opacity: 0.7 }}
        align="center"
        mb={3}
      >
        {props.description}
      </Txt.p>
      <Button href={props.link} underline plain primary>
        {props.buttonText}
      </Button>
    </Flex>
  )
}

function GettingStarted() {
  return (
    <Box py={70} backgroundColor="#f8f9fd">
      <Container>
        <Heading.h3 mb={40}>Learn more about balena</Heading.h3>
        <Flex flexDirection={['column', 'column', 'row', 'row']} mx={-2}>
          <Card
            icon={['fas', 'book-open']}
            link="https://hub.balena.io"
            title="Check out balenaHub"
            description="Browse our project and fleet catalog, including edge AI, remote sensors, surveillance, agriculture, property tech, LoRa, and more."
            buttonText="Browse apps"
          />
          <Card
            icon={['fas', 'code-branch']}
            link="https://balena.io/cloud"
            title="Learn more about balenaCloud"
            description="We provide infrastructure and tools to bring modern app management and deployment to your edge and IoT projects."
            buttonText="Learn more"
          />
          <Card
            icon={['fas', 'terminal']}
            link="https://www.balena.io/docs/learn/more/masterclasses/overview/"
            title="Master our platform"
            description="Take our masterclasses: free, self-contained modules that teach you the ins and outs of our platform."
            buttonText="See masterclasses"
          />
          <Card
            icon={['fas', 'database']}
            title="Join the forums"
            link="https://forums.balena.io/"
            description="Our forums are where we discuss the latest projects and solve problems together."
            buttonText="Join Us"
          />
        </Flex>
      </Container>
    </Box>
  )
}

export default GettingStarted
