import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import {
  Box,
  Container,
  Heading,
  Txt,
  Flex,
  Img,
  Link as RLink,
} from 'rendition'
import styled from 'styled-components'

const StyledH3 = styled(Heading.h3)`
  font-weight: normal;
  align-self: start;
  text-align: left;
`

const StyledLink = styled(RLink)`
  &:hover ${StyledH3}, &:focus ${StyledH3} {
    text-decoration: underline;
    color: #2a506f;
  }
  text-decoration: none;
  border-right: 1px dashed #c1c7dd;
`

const PostRow = (props) => (
  <Flex flexDirection="column" pb={4}>
    <Txt.span
      fontSize={1}
      style={{ textTransform: 'capitalize' }}
      color="primary.main"
    >
      {props.frontmatter.postType}
    </Txt.span>
    <StyledLink color="text.main" is={Link} to={props.fields.slug}>
      <StyledH3 fontSize={3}>{props.frontmatter.title}</StyledH3>
    </StyledLink>
    <Box>
      <Txt.span color="text.light" fontSize="12px" bold>
        {props.frontmatter.date}
      </Txt.span>
      <Txt.span
        color="text.light"
        fontSize="12px"
      >{` / ${props.timeToRead} min`}</Txt.span>
    </Box>
  </Flex>
)

function FeaturedPost() {
  const data = useStaticQuery(graphql`
    query FeaturedPostQuery {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: { eq: "blog-post" }
            visibility: { eq: "public" }
            featured: { eq: true }
          }
        }
        limit: 5
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            excerpt(pruneLength: 300)
            timeToRead
            id
            fields {
              slug
            }
            frontmatter {
              title
              postType
              templateKey
              feature_image
              date(formatString: "MMMM DD, YYYY")
              featured
            }
          }
        }
      }
    }
  `)
  const [firstPost, ...posts] = data.allMarkdownRemark.edges

  return (
    <Container>
      <Flex flexDirection={['column', 'column', 'row', 'row']} pt={5} pb={6}>
        <StyledLink
          width={['100%', '100%', 2 / 3, 2 / 3]}
          pr={25}
          color="text.main"
          is={Link}
          to={firstPost.node.fields.slug}
        >
          <Flex flexDirection="column">
            <Img
              src={firstPost.node.frontmatter.feature_image}
              loading="lazy"
              style={{ borderRadius: 10 }}
            ></Img>
            <Txt.span color="primary.main" pt={3}>
              Featured this Week
            </Txt.span>
            <StyledH3 fontSize={3} py={2}>
              {firstPost.node.frontmatter.title}
            </StyledH3>
            <Txt color="text.light">{firstPost.node.excerpt}</Txt>
            <Box pt={2}>
              <Txt.span color="text.light" fontSize="12px" bold>
                {firstPost.node.frontmatter.date}
              </Txt.span>
              <Txt.span
                color="text.light"
                fontSize="12px"
              >{` / ${firstPost.node.timeToRead} min`}</Txt.span>
            </Box>
          </Flex>
        </StyledLink>

        <Flex
          flexDirection="column"
          pl={25}
          width={['100%', '100%', 1 / 3, 1 / 3]}
        >
          {posts.map((post) => (
            <PostRow {...post.node} key={post.node.id} />
          ))}
        </Flex>
      </Flex>
    </Container>
  )
}

export default FeaturedPost
