import React, { useState } from 'react'
import { Box, Button, Container, Flex, Input, Txt, Img, Link } from 'rendition'
import cloudLogo from '../img/cloud-logo.svg'
function BalenaCloudSignupBanner() {
  const [email, setEmail] = useState('')
  return (
    <Box bg="#f8f9fd" py={4}>
      <Container>
        <Flex
          flexDirection={['column', 'column', 'row', 'row']}
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex
            flexDirection={['column', 'column', 'row', 'row']}
            alignItems="center"
          >
            <Img style={{ height: '80px' }} pr={3} src={cloudLogo} />
            <Box>
              <Txt fontSize="20px" color="text.main">
                Your first ten devices are always free and fully-featured
              </Txt>
              <Txt fontSize="14px" color="#c1c7dd">
                Start many projects in less than 30 minutes!
              </Txt>
            </Box>
          </Flex>
          <Flex flexDirection={['column', 'column', 'row', 'row']}>
            <Input
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Email"
              value={email}
              mt={[3, 3, 0, 0]}
              bg="#fff"
            ></Input>
            <Link
              target="_blank"
              href={`https://dashboard.balena-cloud.com/?email=${email}`}
            >
              <Button ml={[0, 0, 3, 3]} mt={[3, 3, 0, 0]} primary>
                Sign up for free plan
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default BalenaCloudSignupBanner
