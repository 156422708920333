import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import {
  Box,
  Container,
  Flex,
  Heading,
  Img,
  Txt,
  Link as RLink,
} from 'rendition'

function CaseStudy(props) {
  return (
    <Box width={['100%', '100%', 1 / 2, 1 / 2]}>
      <Flex m={2} alignItems="start" flexDirection="column">
        <Img src={props.image} width="100%" style={{ borderRadius: 10 }} loading="lazy"></Img>
        <Txt.span
          mt={3}
          mb={1}
          fontSize={1}
          style={{ alignSelf: 'start' }}
          color="primary.main"
        >
          {props.type}
        </Txt.span>
        <RLink is={Link} to={props.link}>
          <Flex alignItems="start" flexDirection="column">
            <Heading.h4
              fontSize={3}
              mb={0}
              color="text.main"
              style={{ fontWeight: 'normal', lineHeight: 1.3 }}
            >
              {props.title}
            </Heading.h4>
            {props.description && (
              <Txt.p
                my={2}
                color="text.main"
                style={{ opacity: 0.7, lineHeight: 1.71 }}
                fontSize={1}
              >
                {props.description}
              </Txt.p>
            )}
            <Txt.span
              my={1}
              style={{ alignSelf: 'start' }}
              color="text.main"
              fontSize="10px"
            >{`${props.date} / ${props.timeToRead} min`}</Txt.span>
          </Flex>
        </RLink>
      </Flex>
    </Box>
  )
}

function CaseStudies() {
  const data = useStaticQuery(graphql`
    query CaseStudiesQuery {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: { eq: "blog-post" }
            visibility: { eq: "public" }
            tags: { eq: "case study" }
            feature_image: { ne: null }
          }
        }
        limit: 5
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            id
            timeToRead
            fields {
              slug
            }
            frontmatter {
              postType
              title
              date(formatString: "MMMM DD, YYYY")
              feature_image
              primary_tag
            }
            excerpt(pruneLength: 400)
          }
        }
      }
    }
  `)

  const caseStudies = data.allMarkdownRemark.edges.map(
    ({ node: caseStudy }) => ({
      title: caseStudy.frontmatter.title,
      link: caseStudy.fields.slug,
      id: caseStudy.id,
      description: caseStudy.excerpt,
      date: caseStudy.frontmatter.date,
      timeToRead: caseStudy.timeToRead,
      image: caseStudy.frontmatter.feature_image,
      type: caseStudy.frontmatter.primary_tag,
    })
  )

  const [firstCaseStudy, ...restCaseStudies] = caseStudies

  return (
    <Box pb={60}>
      <Container>
        <Heading.h3 pt={50} mb={3}>
          Case studies
        </Heading.h3>
        <Flex flexDirection={['column', 'column', 'row', 'row']}>
          <CaseStudy {...firstCaseStudy} />
          <Flex
            width={['100%', '100%', 1 / 2, 1 / 2]}
            style={{ flexFlow: 'row wrap' }}
          >
            {restCaseStudies.map((caseStudy) => (
              <CaseStudy {...caseStudy} description={undefined} />
            ))}
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default CaseStudies
