import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { Badge, Flex, Box } from 'rendition'
import _ from 'lodash'

export function TagList() {
  const data = useStaticQuery(graphql`
    query allTagsQuery {
      allMarkdownRemark(filter: { frontmatter: { tags: { ne: null } } }) {
        distinct(field: frontmatter___tags)
      }
    }
  `)

  return (
    <Flex mb={30} mt={10} style={{ flexFlow: 'row wrap' }} alignItems="center">
      {data.allMarkdownRemark.distinct.slice(0, 10).map((tagName) => {
        return (
          <Box m={1}>
            <Link to={`/tags/${_.kebabCase(tagName)}`}>
              <Badge shade={16}>{tagName}</Badge>
            </Link>
          </Box>
        )
      })}
      {/* <Box m={1}>
        <Link to="/tags">View all topics</Link>
      </Box> */}
    </Flex>
  )
}
