import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { Fragment } from 'react'
import {
  Box,
  Heading,
  Flex,
  Img,
  Txt,
  Button,
  Container,
  Link as RLink,
} from 'rendition'
import { TagList } from './TagList'
import Hub from '../img/hub-2.png'
import FontAwesomeIcon from './Icon'
import { Post } from './Post'

function Cards(props) {
  // need to handle cases when posts are less than 6 and 7
  let cardList = props.posts.map((post, index) => {
    if (index === 5)
      return (
        <Fragment key={post.id}>
          <Box my={3} px={1} width={['100%', '100%', 1 / 3, 1 / 3]}>
            <RLink target="_blank" href="https://hub.balena.io">
              <Img src={Hub} />
            </RLink>
          </Box>
          <Box my={3} px={1} width={['100%', '100%', 1 / 3, 1 / 3]}>
            <Post {...post} />
          </Box>
        </Fragment>
      )

    return (
      <Box px={1} my={3} key={post.id} width={['100%', '100%', 1 / 3, 1 / 3]}>
        <Post {...post} />
      </Box>
    )
  })

  return (
    <Flex
      style={{ flexFlow: 'row wrap' }}
      flexDirection={['column', 'column', 'row', 'row']}
      mx={-2}
    >
      {cardList}
    </Flex>
  )
}

function PostList() {
  const data = useStaticQuery(graphql`
    query IndexPostListQuery {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: { eq: "blog-post" }
            feature_image: { ne: null }
            visibility: { eq: "public" }
          }
        }
        limit: 11
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            id
            timeToRead
            fields {
              slug
            }
            frontmatter {
              postType
              title
              date(formatString: "MMMM DD, YYYY")
              feature_image
              excerpt
            }
          }
        }
      }
    }
  `)

  const posts = data.allMarkdownRemark.edges.map(({ node: post }) => ({
    title: post.frontmatter.title,
    excerpt: post.frontmatter.excerpt,
    postType: post.frontmatter.postType,
    date: post.frontmatter.date,
    image: post.frontmatter.feature_image,
    timeToRead: post.timeToRead,
    link: post.fields.slug,
    id: post.id,
  }))
  return (
    <>
      <Box bg="#f8f9fd">
        <Container pt={5} pb={3}>
          <Heading.h3 mb={10} fontSize="34px" color="#2a506f">
            Explore by topic
          </Heading.h3>
          <TagList></TagList>
        </Container>
      </Box>
      <Box mt={40}>
        <Container>
          <Cards posts={posts} />
          <Flex justifyContent="flex-end">
            <RLink is={Link} to="/posts">
              <Button plain primary my={5}>
                View more posts{' '}
                <Txt.span pl={2} fontSize={4}>
                  {' '}
                  <FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} />
                </Txt.span>
              </Button>
            </RLink>
          </Flex>
        </Container>
      </Box>
    </>
  )
}

export default PostList
