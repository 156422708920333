import React from 'react'
import Layout from '../components/Layout'
import { Box } from 'rendition'
import FeaturedPost from '../components/FeaturedPost'
import PostList from '../components/PostList'
import CaseStudies from '../components/CaseStudies'
import GettingStarted from '../components/GettingStarted'
import NewsLetterBanner from '../components/NewsLetterBanner'
import BalenaCloudSignupBanner from '../components/BalenaCloudSignupBanner'
import LatestBalenaCloud from '../components/LatestBalenaCloud'

export const IndexPageTemplate = () => (
  <Box>
    <FeaturedPost />
    <PostList />
    <NewsLetterBanner />
    <LatestBalenaCloud />
    <BalenaCloudSignupBanner />
    <CaseStudies />
    <GettingStarted />
  </Box>
)

const IndexPage = () => {
  return (
    <Layout>
      <IndexPageTemplate />
    </Layout>
  )
}

export default IndexPage
