import FontAwesomeIcon from './Icon'
import React from 'react'
import { Box, Button, Container, Flex, Input, Txt } from 'rendition'
import { graphql, useStaticQuery } from 'gatsby'
function NewsLetterBanner() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          mailchimpForm {
            u
            host
            newsletterId
            kitraId
          }
        }
      }
    }
  `)
  const { mailchimpForm } = data.site.siteMetadata

  return (
    <Box bg="#2a506f" py={4}>
      <Container>
        <Flex
          justifyContent="space-between"
          flexDirection={['column', 'column', 'row', 'row']}
          alignItems="center"
        >
          <Flex
            flexDirection={['column', 'column', 'row', 'row']}
            alignItems="center"
          >
            <Txt.span fontSize="64px" pr={3} color="#c1c7dd">
              <FontAwesomeIcon icon={['far', 'envelope']} />
            </Txt.span>
            <Box>
              <Txt fontSize="20px" color="#fff">
                Get latest insights, case studies, and more from balena
              </Txt>
              <Txt fontSize="14px" color="#c1c7dd">
                We’ll send you an email whenever we launch a new project.
              </Txt>
            </Box>
          </Flex>
          <form
            method="POST"
            target="_blank"
            action={`//${mailchimpForm.host}/subscribe/post?u=${mailchimpForm.u}&amp;id=${mailchimpForm.newsletterId}`}
          >
            <Flex flexDirection={['column', 'column', 'row', 'row']}>
              <Input
                type="text"
                name="EMAIL"
                placeholder="Email"
                bg="#fff"
              ></Input>
              <div style={{ display: 'none' }}>
                <input
                  type="text"
                  name={`b_${mailchimpForm.u}_${mailchimpForm.newsletterId}`}
                  tabIndex="-1"
                />
              </div>
              <Button type="submit" ml={3} primary>
                Subscribe now
              </Button>
            </Flex>
          </form>
        </Flex>
      </Container>
    </Box>
  )
}

export default NewsLetterBanner
